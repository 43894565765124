import type { LinkProps as NextLinkProps } from "next/link"
import NextLink from "next/link"

export type LinkProps = Omit<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  "href"
> &
  (
    | Omit<NextLinkProps, "onClick">
    | {
        isExternal: true
        href: string
      }
  )

export const Link: React.FC<LinkProps> = ({ children, ...props }) => {
  if ("isExternal" in props) {
    const { isExternal: _isExternal, href, ...anchorProps } = props

    return (
      <a {...anchorProps} href={href} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    )
  }

  const {
    as,
    replace,
    scroll,
    shallow,
    passHref,
    prefetch = false,
    href,
    ...anchorProps
  } = props

  return (
    <NextLink
      href={href}
      as={as}
      replace={replace}
      scroll={scroll}
      shallow={shallow}
      passHref={passHref}
      prefetch={prefetch}
    >
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a {...anchorProps}>{children}</a>
    </NextLink>
  )
}

export default Link
