import type { RefObject } from "react"
import { useEffect, useRef, useState } from "react"
import type { SharedImgixAndSourceProps } from "react-imgix"
import fastdom from "fastdom"

import type { Maybe } from "~/types/ts-utils"
import { imageOrFallback } from "~/utils/images/imageOrFallback"
import { buildURL } from "~/utils/urls/buildURL"

type ImgixParams = SharedImgixAndSourceProps["imgixParams"]

export type LazyImageOptions = {
  imgixParams?: ImgixParams
  lazySize?: ImgixParams
}

export function useLazyImageRef<T extends HTMLElement = HTMLImageElement>(
  src: Maybe<string>,
  {
    imgixParams = { fit: "crop", crop: "center" },
    lazySize = { h: 10, w: 10 },
  }: LazyImageOptions = {},
): { ref: RefObject<T>; url: string } {
  const imageRef = useRef<T>(null)
  const [imageSize, setImageOptions] = useState(lazySize)

  useEffect(() => {
    const resizeImage = () => {
      fastdom.measure(() => {
        const image = imageRef.current
        if (!image) return

        const { clientHeight, clientWidth } = image

        setImageOptions({ h: clientHeight, w: clientWidth })
      })
    }

    resizeImage()
    window.addEventListener("resize", resizeImage)

    return () => {
      window.removeEventListener("resize", resizeImage)
    }
  }, [])

  const imageURL = buildURL(imageOrFallback(src), {
    ...imgixParams,
    ...imageSize,
  })

  return { ref: imageRef, url: imageURL }
}
