import React from "react"
import { useTranslation } from "react-i18next"
import clsx from "clsx"

import LogoBlack from "~/assets/logos/logo-black.svg"
import LogoBlackWhite from "~/assets/logos/logo-bw.svg"
import LogoPurple from "~/assets/logos/logo-purple.svg"
import LogoWhiteBlack from "~/assets/logos/logo-wb.svg"
import LogoYellow from "~/assets/logos/logo-yellow.svg"

export const MainNavLogo: React.FC = () => {
  const { t } = useTranslation("navbar")
  const altText = t("logo.alt")
  const classNames: typeof clsx = (...c) => clsx(c, "hidden pt-1 w-40")

  return (
    <>
      <img
        {...LogoYellow}
        className={classNames("theme-bw:!hidden dark:inline")}
        alt={altText}
      />
      <img
        {...LogoPurple}
        className={classNames("light:inline theme-bw:hidden")}
        alt={altText}
      />
      <img
        {...LogoBlack}
        className={classNames("theme-peach:inline")}
        alt={altText}
      />
      <img
        {...LogoBlackWhite}
        className={classNames("theme-bw:inline dark:hidden")}
        alt={altText}
      />
      <img
        {...LogoWhiteBlack}
        className={classNames("dark:theme-bw:inline")}
        alt={altText}
      />
    </>
  )
}
